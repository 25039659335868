import { OrderTypeEnum } from '@/pages/order/index/type';
import useConstants from '@/pages/order/index/useConstants';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@@/exports';
import useUrlState from '@ahooksjs/use-url-state';
import { Divider } from '@nextui-org/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectSetting from '@/setting/projectSetting';

//status -2：已强平，-1：已撤销，0：已结算，1：委托中，2：持仓中
export default ({ data }: any) => {
  const { t } = useTranslation();
  const { SubscribeEnum } = useConstants();
  const [url = {}] = useUrlState();
  const { type, coinName }: any = url || {};
  const { joinSymbol } = useModel('exchange');
  const infoArray = [
    {
      lab: t('状态'),
      value: SubscribeEnum[data?.status],
    },
    {
      lab: t('申购数量'),
      value: data?.buyNum,
    },
    {
      lab: t('申购金额'),
      value: joinSymbol({
        price: data?.buyAmount,
        currency: ProjectSetting.APP_DEFAULT_CURRENCY,
        isShowCoinName: true,
      }),
    },
    {
      lab: t('发行价格'),
      value: joinSymbol({
        price: data?.price,
        currency: ProjectSetting.APP_DEFAULT_CURRENCY,
        isShowCoinName: true,
      }),
    },
    {
      lab: t('中签数量'),
      value: data?.winningLotsNum,
    },
    {
      lab: t('已成交数量'),
      value: data?.transactionNum,
    },

    {
      lab: t('已成交金额'),
      value: joinSymbol({
        price: data?.transactionAmount,
        currency: ProjectSetting.APP_DEFAULT_CURRENCY,
        isShowCoinName: true,
      }),
    },
    +data?.unpaidAmount !== 0
      ? {
        lab: t('需要缴纳金额'),
        value: joinSymbol({
          price: data?.unpaidAmount,
          currency: ProjectSetting.APP_DEFAULT_CURRENCY,
          isShowCoinName: true,
        }),
      }
      : null,
  ].filter((item) => item !== null);
  const filterInfoArray = useMemo(() => {
    if ([2, 5].includes(data?.status)) {
      return infoArray.slice(0, 5);
    }
    return infoArray;
  }, [infoArray]);

  return (
    <div className="mt-4">
      <div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('类型')}</span>
          <span className="text-backContrastColor bg-[transparent] flex items-center">
            {type === OrderTypeEnum.IPO
              ? t('IPO')
              : type === OrderTypeEnum.PLACING
                ? t('配售')
                : t('IEO')}
          </span>
        </div>
        {filterInfoArray.map((item: any) => {
          return (
            <div className="flex justify-between mb-2 text-sm" key={item}>
              <span className="text-auxiliaryTextColor">{item.lab}</span>
              <span className="text-backContrastColor bg-[transparent] flex items-center">
                {item.value}
              </span>
            </div>
          );
        })}
      </div>
      <Divider className="my-4 !bg-backgroundAuxiliaryColor" />
    </div>
  );
};
