import {LineMdCoffeeLoop} from '@/assets/icons/mine/LineMdCoffeeLoop';
import {FluentMdl2CompletedSolid} from '@/assets/icons/order/FluentMdl2CompletedSolid';
import {useTranslation} from 'react-i18next';
import useConstants from "@/pages/order/index/useConstants";

//status -2：已强平，-1：已撤销，0：已结算，1：委托中，2：持仓中
export default ({data}: { data: any }) => {
    const {t} = useTranslation();
    const {SubscribeEnum} = useConstants()
    return (
        <div className="flex flex-col justify-center items-center mt-4 ">
            {[2, 4, 5].includes(data?.status) && (
                <div className="bg-success/20 text-success w-12 h-12 rounded-[100%] flex justify-center items-center">
                    <FluentMdl2CompletedSolid width="2.5em" height="2.5em"/>
                </div>
            )}
            {[0, 1, 3].includes(data?.status) && (
                <LineMdCoffeeLoop width="2.5em" height="2.5em"/>
            )}

            {[0, 1, 3].includes(data?.status) && (
                <span className="text-base mt-2 -[3px] font-bold">{t('持仓中')}</span>
            )}

            {[2, 4, 5].includes(data?.status) && (
                <span className="text-success-600 text-base mt-2 -[3px] font-bold">
          {SubscribeEnum?.[data?.status]}
        </span>
            )}
        </div>
    );
};
