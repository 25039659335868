import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';
import BaseTopNav from '@/components/base/baseTopNav';

import FlashExchangeInformation from '@/pages/order/orderDetail/components/FlashExchangeInformation';
import { OrderCoinDetailFormation } from '@/pages/order/orderDetail/components/OrderCoinDetailFormation';
import { OrderCoinStatusIcon } from '@/pages/order/orderDetail/components/OrderCoinStatusIcon';
import AiChildOrderDetailStatusIcon from "@/pages/order/orderDetail/components//AiChildOrderDetailStatusIcon"
import { OrderFlashExchangeStatusIcon } from '@/pages/order/orderDetail/components/OrderFlashExchangeStatusIcon';
import RecordsOfFundsDetailInformation from '@/pages/order/orderDetail/components/RecordsOfFundsDetailInformation';
import RecordsOfFundsDetailsStatusIcon from '@/pages/order/orderDetail/components/RecordsOfFundsDetailsStatusIcon';
import {
  fastOrderDetailApi,
  getCoinOrderDetail,
  getCoinTradeDetailApi,
  getContractOrderDetail,
  getIEOOrderDetailsApi,
  transferDetailsApi,
  aiChildOrderDetailSubApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useParams } from '@@/exports';
import useUrlState from '@ahooksjs/use-url-state';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderTypeEnum } from '../index/type';
import OrderDetailFast from './components/OrderDetailFast';
import OrderDetailInformation from './components/OrderDetailInformation';
import AiChildOrderDetailInformation from './components/AiChildOrderDetailInformation';
import OrderDetailStatusIcon from './components/OrderDetailStatusIcon';
import SubscriptionDetailInformation from './components/SubscriptionDetailInformation';
import SubscriptionDetailsStatusIcon from './components/SubscriptionDetailsStatusIcon';
import TransferInformation from './components/TransferInformation';

export default () => {
  const { id, type } = useParams();
  const [url] = useUrlState();
  const { t } = useTranslation();
  // 是否合约
  const isContract = ![
    'coin',
    'transfer',
    'fastContract',
    'recordsOfFunds',
    'flashExchange',
    'aiChildOrderDetail',
    OrderTypeEnum.IEO,
  ]?.includes(url?.type);

  // 合约
  const { data = {} as any, loading } = useReq(
    () => getContractOrderDetail({ id }),
    {
      ready: isContract,
      formatResult: (res: any) => {
        return res?.data ?? {};
      },
    },
  ) as any;

  // 是否快速 合约
  const isFastContract = url?.type === 'fastContract';

  // 极速合约详情
  const { data: fastOrderDetail = {} as any, loading: fastLoading } = useReq(
    () => fastOrderDetailApi({ id }),
    {
      ready: isFastContract,
      formatResult: (res: any) => {
        return res?.data ?? {};
      },
    },
  ) as any;

  // 币币详情
  const { data: coinDataDetail = {} as any, loading: coinLoading } = useReq(
    () => getCoinOrderDetail({ id }),
    {
      ready: url?.type === 'coin',
      formatResult: (res: any) => {
        return res?.data ?? {};
      },
    },
  ) as any;

  // 闪兑详情
  const {
    data: flashExchangeDetail = {} as any,
    loading: flashExchangeLoading,
  } = useReq(() => getCoinTradeDetailApi({ id }), {
    ready: url?.type === 'flashExchange',
    formatResult: (res: any) => {
      return res?.data ?? {};
    },
  }) as any;

  // 划转详情
  const { data: transferDetails = {} as any, loading: transferDetailsLoading } =
    useReq(() => transferDetailsApi({ id }), {
      ready: url?.type === 'transfer',
      formatResult: (res: any) => {
        return res?.data ?? {};
      },
    }) as any;
  // ai 子仓详情
  const { data: aiChildOrderDetail = {} as any, loading: aiChildLoading } = useReq(() =>
    aiChildOrderDetailSubApi({ id }),
    {
      ready: url?.type === 'aiChildOrderDetail',
      formatResult: (res: any) => {
        return res?.data?? {};
      },
    },
  ) as any;
  const list = useMemo(() => {
    if (data) {
      return [data];
    }
    if (coinDataDetail) {
      return [coinDataDetail];
    }
    return [];
  }, [data, coinDataDetail]);

  // 加载重置
  const resetLoading = useMemo(() => {
    if(url?.type === 'aiChildOrderDetail'){
      return aiChildLoading;
    }else if (url?.type === 'coin') {
      return coinLoading;
    } else if (url?.type === 'transfer') {
      return transferDetailsLoading;
    } else if (url?.type === 'flashExchange') {
      return flashExchangeLoading;
    }else if (isContract) {
      return loading;
    } else if (isFastContract) {
      return fastLoading;
    }
  }, [data, coinDataDetail, coinLoading, loading, url, fastLoading]);

  // IEO详情
  const { data: IEODetails, loading: ieoDetailsLoading = false } = useReq(
    () => getIEOOrderDetailsApi({ id }),
    {
      ready: url?.type === OrderTypeEnum.IEO,
      formatResult: (res: any) => {
        return res?.data ?? {};
      },
    },
  ) as any;
  
  const renderTitle = useMemo(() => {
    if (data?.name) return data?.name;
    if (fastOrderDetail?.name) return fastOrderDetail?.name;
    if (coinDataDetail?.alias) return coinDataDetail?.alias;
    if (transferDetails?.name) return t('划转');
    if (IEODetails?.alias) return IEODetails?.alias;
    if(aiChildOrderDetail?.alias) return aiChildOrderDetail?.alias;
  }, [data, coinDataDetail, transferDetails,aiChildOrderDetail]);

  return (
    <div>
      <BaseTopNav
        title={
          <div className="flex items-center">
            <span className="text-backContrastColor text-base font-bold">
              {renderTitle}
            </span>
            {isContract && !!renderTitle && (
              <span className="font-normal bg-backgroundAuxiliaryColor ml-1 rounded-[4px] p-1 text-xs">
                {t('永续')}
              </span>
            )}
            {isFastContract && !!renderTitle && (
              <span className="font-normal bg-backgroundAuxiliaryColor ml-1 rounded-[4px] p-1 text-xs">
                {t('极速')}
              </span>
            )}
          </div>
        }
      />
      <BaseLoadingDisplay
        loading={
          resetLoading || (url?.type === OrderTypeEnum.IEO && ieoDetailsLoading)
        }
        list={resetLoading ? [] : list}
      >
        <>
          <div className="px-4">
            {/*闪兑详情*/}
            {url?.type === 'flashExchange' && (
              <>
                <OrderFlashExchangeStatusIcon data={flashExchangeDetail} />
                <FlashExchangeInformation data={flashExchangeDetail} />
              </>
            )}
            {url?.type === 'transfer' && (
              <>
                <OrderCoinStatusIcon data={{ status: 0 }} />
                <TransferInformation data={transferDetails} />
              </>
            )}
            {url?.type === 'aiChildOrderDetail'&&(
              <>
                <AiChildOrderDetailStatusIcon data={aiChildOrderDetail} />
                <AiChildOrderDetailInformation data={aiChildOrderDetail} />
              </>
            )}
            {url?.type === 'coin' && (
              <>
                <OrderCoinStatusIcon data={coinDataDetail} />
                <OrderCoinDetailFormation data={coinDataDetail} />
              </>
            )}
            {/* 合约 */}
            {isContract && (
              <>
                <OrderDetailStatusIcon data={data} />
                <OrderDetailInformation data={data} />
              </>
            )}

            {/* // 快速合约 */}
            {isFastContract && <OrderDetailFast data={fastOrderDetail} />}

            {/*IPO详情*/}
            {url?.type === OrderTypeEnum.IEO && !ieoDetailsLoading && (
              <>
                <SubscriptionDetailsStatusIcon data={IEODetails} />
                <SubscriptionDetailInformation data={IEODetails} />
              </>
            )}

            {/*资金记录详情*/}
            {url?.type === 'recordsOfFunds' && (
              <>
                <RecordsOfFundsDetailsStatusIcon />
                <RecordsOfFundsDetailInformation />
              </>
            )}
          </div>
        </>
      </BaseLoadingDisplay>
    </div>
  );
};
