import CacheEnum from '@/enums/cacheEnum';
import { toJsonData } from '@/utils/socket';

export default () => {
  const infoArray = toJsonData(
    sessionStorage.getItem(CacheEnum.APP_FUNDS_RECORD_DETAIL) || ({} as any),
  );

  return (
    <>
      {infoArray?.map((item: any) => {
        return (
          <div className="flex justify-between mb-2 text-sm" key={item}>
            <span className={`text-auxiliaryTextColor ${item?.labExtraClass}`}>
              {item.lab}
            </span>
            <span
              className={`text-backContrastColor bg-[transparent] flex items-center ${item?.valueExtraClass}`}
            >
              {item.value}
            </span>
          </div>
        );
      })}
    </>
  );
};
