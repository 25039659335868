import { WalletTypeEnum } from '@/enums/businessEnum';
import RenderUtil from '@/utils/RenderUtil';
import { useTranslation } from 'react-i18next';

export default (props: any) => {
  const { data } = props;
  const { t } = useTranslation();

  const fromWalletType = data?.fromWalletType + '';
  const toWalletType = data?.toWalletType + '';

  return (
    <div className="mt-4">
      <div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('交易时间')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.formatDate(data?.createTime, 'YYYY-MM-DD HH:mm:ss')}
          </span>
        </div>
      </div>
      <div className="mt-10">
        <div className="titleText text-lg mb-4">{t('成交详情')}</div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('来源钱包')}</span>
          {fromWalletType === WalletTypeEnum.COIN && (
            <span className="text-backContrastColor">{t('现货')}</span>
          )}
          {fromWalletType === WalletTypeEnum.CONTRACT && (
            <span className="text-backContrastColor">{t('合约')}</span>
          )}
          {fromWalletType === WalletTypeEnum.FIAT && (
            <span className="text-backContrastColor">{t('法币')}</span>
          )}
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('目标钱包')}</span>
          {toWalletType === WalletTypeEnum.COIN && (
            <span className="text-backContrastColor">{t('现货')}</span>
          )}
          {toWalletType === WalletTypeEnum.CONTRACT && (
            <span className="text-backContrastColor">{t('合约')}</span>
          )}
          {toWalletType === WalletTypeEnum.FIAT && (
            <span className="text-backContrastColor">{t('法币')}</span>
          )}
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('来源划转前余额')}</span>
          <span className="text-backContrastColor">
            {' '}
            {RenderUtil.FormatAmount(data?.fromBeforeBalance) ?? '--'}{' '}
            {data?.coinName}
          </span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('来源划转后余额')}</span>
          <span className="text-backContrastColor">
            {' '}
            {RenderUtil.FormatAmount(data?.fromAfterBalance) ?? '--'}{' '}
            {data?.coinName}
          </span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('目标划转前余额')}</span>
          <span className="text-backContrastColor">
            {' '}
            {RenderUtil.FormatAmount(data?.toBeforeBalance) ?? '--'}{' '}
            {data?.toCoinName}
          </span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('目标划转后余额')}</span>
          <span className="text-backContrastColor">
            {' '}
            {RenderUtil.FormatAmount(data?.toAfterBalance) ?? '--'}{' '}
            {data?.toCoinName}
          </span>
        </div>
      </div>
    </div>
  );
};
