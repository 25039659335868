import { WalletTypeEnum } from '@/enums/businessEnum';
import RenderUtil from '@/utils/RenderUtil';
import { useTranslation } from 'react-i18next';

export default (props: any) => {
  const { data } = props;
  const { t } = useTranslation();
  return (
    <div className="mt-4">
      <div className="mt-10">
        <div className="titleText text-lg mb-4">{t('成交详情')}</div>
        {/* <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('类型')}</span>
          <span className="text-backContrastColor">
            {data?.type === '1' ? t('市价') : t('限价')}
          </span>
        </div> */}
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('支付渠道')}</span>
          <span className="text-backContrastColor">
            {data?.walletType === WalletTypeEnum.COIN && (
              <span className="text-backContrastColor">{t('现货')}</span>
            )}
            {data?.walletType === WalletTypeEnum.CONTRACT && (
              <span className="text-backContrastColor">{t('合约')}</span>
            )}
            {data?.walletType === WalletTypeEnum.FIAT && (
              <span className="text-backContrastColor">{t('法币')}</span>
            )}
          </span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('从')}</span>
          <span className="text-backContrastColor">
            {data?.from}
            {' ' + data?.baseCoinAlias}
          </span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('交易手续费')}</span>
          <span className="text-backContrastColor">{data?.fee}</span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('兑换比率')}</span>
          <span className="text-backContrastColor">
            1{' ' + data?.baseCoinAlias}≈{data?.rate}
            {' ' + data?.exchangeCoin}
          </span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('下单时间')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.formatDate(data?.createTime,"YYYY-MM-DD HH:mm:ss")}
          </span>
        </div>
      </div>
    </div>
  );
};
