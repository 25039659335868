import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';
import ProjectSetting from '@/setting/projectSetting';
import RenderUtil from '@/utils/RenderUtil';
import { FormatUtils } from '@/utils/format';
import { truncateNumber } from '@/utils/number';
import { calculateProfitLoss, calculateProfitLossRatio } from '@/utils/trade';
import { useModel } from '@@/exports';
import useUrlState from '@ahooksjs/use-url-state';
import { Divider } from '@nextui-org/react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useConstants from '../../../funds/orderHistory/index/useConstants';

enum TransactionTypeEnum {
  //限价
  LIMIT = 2,
  //市价
  MARKET = 1,
}

export default ({ data }: any) => {
  const { t } = useTranslation();
  const { getSocketRowByOrderId, getSocketRowByName } = useModel('socket');
  const socketData = getSocketRowByName(data?.symbol ?? data?.baseSymbol);
  const orderSocket = getSocketRowByOrderId(data?.id as any);

  /**
   * 状态（-2：已强平，-1：已撤销，0：已结算，1：委托中，2：持仓中）
   */
  const { renderFunds } = useModel('user');
  // 是否历史
  const ishsy =
    data?.status === 0 || data?.status === -2 || data?.status === -3;
  const isCancel = data?.status === -1;

  const plAmount = useMemo(() => {
    // 盈亏
    if (ishsy) return data?.plAmount;

    // 计算
    return calculateProfitLoss({
      marketPrice: socketData?.a || orderSocket?.currentPrice,
      openPrice: data?.endPrice,
      leverage: orderSocket?.lever ?? data?.lever,
      margin: data?.earnestMoney,
      isLong: data?.direction === 1, // 是否 开多
    });
  }, [data?.status, renderFunds, socketData]);

  const plRatio = useMemo(() => {
    // 盈亏 比例
    if (ishsy) return data?.plRatio;
    // return getContractProfitAndLoss(data?.id)?.plRatio;

    return calculateProfitLossRatio({
      marketPrice: socketData?.a || orderSocket?.currentPrice,
      openPrice: data?.endPrice,
      leverage: orderSocket?.lever ?? data?.lever,
      lotSize: data?.firstHand,
      isLong: data?.direction === 1, // 是否 开多
    });
  }, [data?.status, renderFunds, socketData]);

  const [urlState] = useUrlState<any>({});
  const { source } = urlState || {};
  const { sourceOptions } = useConstants();
  const { appInfo = {} } = useModel('appInfo');
  const { AUTH_LOGIN_CAPTCHA = true } = appInfo;
  const { joinSymbol } = useModel('exchange');
  const { sendMsg, isSub } = useModel('socket');

  useEffect(() => {
    sendMsg(
      `{"type":"SUB","data": ${JSON.stringify(
        data?.symbol ?? data?.baseSymbol,
      )}}`,
    );
  }, [data?.symbol, data?.baseSymbol, isSub]);

  const priceAccuracy = data?.priceAccuracy ?? orderSocket?.priceAccuracy ?? 6;

  // （（行情 - 开仓价格）* 杠杆） / （开仓价格*一手的数量） 计算盈亏比
  // firstHand = 一手的数量

  /**
   *
   * (行情价格 - 开仓价格)/开仓价格) * (保证金*杠杆)
   */

  return (
    <div className="mt-4">
      <div>
        <div className="flex justify-between items-center mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('订单号')}</span>
          <div className="flex-1 flex justify-end items-center">
            <span className="text-backContrastColor bg-[transparent] flex items-center">
              {data?.no}
            </span>
            <PhCopyFill
              className="cursor-pointer ml-2"
              onClick={() => FormatUtils.copyText(data?.no)}
            />
          </div>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('类型')}</span>
          <div>
            <span className="text-errorColor">
              {data?.type === TransactionTypeEnum.LIMIT
                ? `${t('限价')}`
                : `${t('市价')}`}
            </span>
            <span className="mx-1">/</span>
            {data?.direction === 1 && (
              <span className=" text-successColor flex-shrink-0">
                {t('买涨')}
              </span>
            )}
            {data?.direction === 2 && (
              <span className="text-errorColor flex-shrink-0">{t('买跌')}</span>
            )}
          </div>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">
            {t('数量')} / {t('保证金')}
          </span>
          <span className="text-backContrastColor">
            {RenderUtil.FormatAmount(data?.num, 0)} {t('张')}
            <span className="mx-1">/</span>
            {joinSymbol({
              price: RenderUtil.FormatAmount(data?.earnestMoney, priceAccuracy),
              currency: ProjectSetting.APP_DEFAULT_CURRENCY,
              isShowCoinName: true,
            })}
          </span>
        </div>

        {source && (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('来源')}</span>
            <span className="text-backContrastColor">
              {
                sourceOptions?.find(
                  (i: any) => Number(i.value) === Number(source),
                )?.text
              }
            </span>
          </div>
        )}

        {data?.limitPrice ? (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('委托价格')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.FormatAmount(data?.limitPrice, priceAccuracy, true)}
            </span>
          </div>
        ) : null}
      </div>
      <Divider className="my-4 !bg-backgroundAuxiliaryColor" />

      {/* <div className="flex justify-between mb-2 text-sm">
        <span className="text-auxiliaryTextColor">{t('实现盈亏')}</span>
        <div
          className={`text-backContrastColor ${
            plAmount > 0 ? ' text-successColor' : 'text-errorColor'
          }`}
        >
          {RenderUtil.getDynamicPriceString(plAmount, 2)}{' '}
          {ProjectSetting.APP_DEFAULT_CURRENCY}
        </div>
      </div> */}

      <div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('创建时间')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.formatDate(data?.createTime, 'YYYY-MM-DD HH:mm:ss')}
          </span>
        </div>

        {!data?.limitPrice && ishsy && (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('成交时间')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.formatDate(data?.completeTime, 'YYYY-MM-DD HH:mm:ss')}
            </span>
          </div>
        )}

        {data?.limitPrice && ishsy && (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('成交时间')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.formatDate(data?.endTime)}
            </span>
          </div>
        )}

        {isCancel && (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('撤销时间')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.formatDate(data?.endTime)}
            </span>
          </div>
        )}
      </div>
      <div className="mt-10">
        <div className="titleText text-lg mb-4">{t('成交明细')}</div>
        {/* {data?.completeTime && (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('日期')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.formatDate(data?.completeTime)}
            </span>
          </div>
        )} */}

        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('数量(张)')}</span>
          <span className="text-backContrastColor">
            {truncateNumber(data?.num, 0)}
            {' ' + t('张')}
          </span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('开仓价')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.FormatAmount(data?.endPrice, priceAccuracy, true)}
          </span>
        </div>

        {ishsy ? (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('平仓价格')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.FormatAmount(
                data?.completePrice,
                priceAccuracy,
                true,
              )}
            </span>
          </div>
        ) : (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('强平价格')}</span>
            <span className="text-backContrastColor">
              {data?.forcedLiquidationPrice
                ? RenderUtil.FormatAmount(
                    data?.forcedLiquidationPrice,
                    priceAccuracy,
                    true,
                  )
                : '--'}
            </span>
          </div>
        )}

        {!ishsy && data?.status !== -1 && (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('当前价格')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.FormatAmount(
                socketData.a || orderSocket?.currentPrice,
                priceAccuracy,
                true,
              )}
            </span>
          </div>
        )}

        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('手续费')}</span>
          <span className="text-backContrastColor">
            {joinSymbol({
              price: RenderUtil.FormatAmount(data?.commission, priceAccuracy),
              currency: ProjectSetting.APP_DEFAULT_CURRENCY,
              isShowCoinName: true,
            })}
          </span>
        </div>

        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">
            {ishsy ? t('已实现盈亏') : t('未实现盈亏')}
          </span>
          <div
            className={`text-backContrastColor ${
              plAmount > 0 ? ' text-successColor' : 'text-errorColor'
            }`}
          >
            {plAmount > 0 ? '+' : ''}
            {joinSymbol({
              price: RenderUtil.FormatAmount(plAmount, priceAccuracy),
              currency: ProjectSetting.APP_DEFAULT_CURRENCY,
              isShowCoinName: true,
            })}
          </div>
        </div>

        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('盈亏比')}</span>
          <div
            className={`text-backContrastColor ${
              plRatio > 0 ? ' text-successColor' : 'text-errorColor'
            }`}
          >
            {plRatio > 0 ? '+' : ''}
            {RenderUtil.formatAndCeilToTwoDecimals(plRatio)} %
          </div>
        </div>

        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">
            {t('止盈')}/{t('止损')}
          </span>
          <span className="text-backContrastColor">
            {data?.stopProfitPrice
              ? RenderUtil.FormatAmount(data?.stopProfitPrice, priceAccuracy)
              : '--'}
            <span className="mx-1">/</span>
            {data?.stopLossPrice
              ? RenderUtil.FormatAmount(data?.stopLossPrice, priceAccuracy)
              : '--'}
          </span>
        </div>

        {AUTH_LOGIN_CAPTCHA && (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('保证金模式')}</span>
            {+data?.earnestMode === 1 && (
              <span className="text-backContrastColor">{t('全仓')}</span>
            )}
            {+data?.earnestMode === 2 && (
              <span className="text-backContrastColor">{t('逐仓')}</span>
            )}
          </div>
        )}
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('杠杆')}</span>
          <span className="text-backContrastColor">{data?.lever}x</span>
        </div>
      </div>
    </div>
  );
};
