import { LineMdCoffeeLoop } from '@/assets/icons/mine/LineMdCoffeeLoop';
import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';
import { FluentMdl2CompletedSolid } from '@/assets/icons/order/FluentMdl2CompletedSolid';
import BaseCountDown from '@/components/base/baseCountDown';
import { TradeDirection } from '@/enums/FastContract';
import ProjectSetting from '@/setting/projectSetting';
import { cn } from '@/utils';
import RenderUtil from '@/utils/RenderUtil';
import { FormatUtils } from '@/utils/format';
import { calculateTime } from '@/utils/order';
import { Divider } from '@nextui-org/react';
import { useModel, useParams } from '@umijs/max';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 快速合约 订单详情
 */
export default ({ data }: any) => {
  const { t } = useTranslation();

  const showTextMap = {
    '-2': t('已强平'),
    '-1': t('已撤销'),
    '0': t('全部成交') + '100%',
    '1': t('委托中'),
    '2': t('持仓中'),
  } as any;

  const { id } = useParams();
  const { getSocketRowByOrderId, getSocketRowByName } = useModel('socket');
  const orderSocket = getSocketRowByOrderId(id as any);
  const socketData = getSocketRowByName(data?.symbol);
  const priceAccuracy: number =
    data?.priceAccuracy ?? orderSocket?.priceAccuracy ?? 2; //保留的小数位数

  /**
   * 状态（-2：已强平，-1：已撤销，0：已结算，1：委托中，2：持仓中）
   */
  // 是否历史
  const ishsy = data?.status === 0 || data?.status === -2;
  const plAmount = useMemo(() => {
    // 历史 盈亏
    if (ishsy) return data?.plAmount;
    const endPrice: number = data?.endPrice; //开仓价
    const currentPrice = socketData?.a || orderSocket?.currentPrice; //当前价
    const direction: TradeDirection = data?.direction; //交易方向 1：做多，2：做空）
    const amount: number = data?.amount; // 购买金额
    const profitRate: number = data?.plRatio / 100; // 配置的赢利率

    let profit: number = 0;
    if (direction === TradeDirection.Long) {
      profit = endPrice < currentPrice ? amount * profitRate : amount * -1;
    } else if (direction === TradeDirection.Short) {
      profit = endPrice > currentPrice ? amount * profitRate : amount * -1;
    }

    return profit;
  }, [data?.status]);

  const plRatio = useMemo(() => {
    // 盈亏 比例
    if (ishsy) {
      // 只要历史 盈亏 为负数，则是 ，-100%
      if (data?.plAmount < 0) {
        return '-100';
      }
      return data?.plRatio;
    }
    const endPrice: number = data?.endPrice; //开仓价
    const currentPrice = socketData?.a || orderSocket?.currentPrice; //当前价
    const direction: TradeDirection = data?.direction; //交易方向 1：做多，2：做空）
    const amount: number = data?.amount; // 购买金额
    const profitRate: number = data?.plRatio; // 假设配置的赢利率为10%

    let profit: number = 0;
    if (direction === TradeDirection.Long) {
      profit = endPrice < currentPrice ? profitRate : amount * -1;
    } else if (direction === TradeDirection.Short) {
      profit = endPrice > currentPrice ? profitRate : amount * -1;
    }
    return profit;
  }, [data?.status]);

  const { currentUpClass, currentDownClass } = useModel('system');

  const timeInSeconds = calculateTime(data?.createTime); // 计算已经过去的秒数
  const tradingTime = data?.tradingTime; // 时间倒计时 总的

  // 显示在订单上面的剩余时间
  let timeValue = tradingTime ?? 0;
  if (timeInSeconds >= tradingTime) {
    // 时间已经过去完成了，显示 0就行
    timeValue = 0;
  } else if (tradingTime > timeInSeconds) {
    // 时间已经过去多少，显示中要扣除
    timeValue = tradingTime - timeInSeconds;
  }

  const renderDetailItems = [
    { label: t('订单号'), value: data?.no, copyable: true },
    {
      label: t('类型'),
      value: (
        <>
          <span>{t('极速合约')}</span>
          <span className="mx-1">/</span>
          <span
            className={cn(
              'flex-shrink-0',
              data?.direction === TradeDirection.Long
                ? currentUpClass
                : currentDownClass,
            )}
          >
            {data?.direction === TradeDirection.Long ? t('买涨') : t('买跌')}
          </span>
        </>
      ),
    },

    {
      label: t('结算倒计时'),
      value: (
        <div>
          <BaseCountDown
            className="font-bold text-[15px] text-errorColor"
            time={timeValue}
            suffix="S"
          />
        </div>
      ),
      condition: !ishsy,
    },
    {
      label: t('交易时间'),
      value: <div>{tradingTime} S</div>,
      condition: ishsy,
    },
    { label: t('创建时间'), value: RenderUtil.formatDate(data?.createTime,"YYYY-MM-DD HH:mm:ss") },
    {
      label: t('更新时间'),
      value: RenderUtil.formatDate(data?.updateTime,"YYYY-MM-DD HH:mm:ss"),
    },
    {
      label: t('平仓时间'),
      value: RenderUtil.formatDate(data?.completeTime,"YYYY-MM-DD HH:mm:ss"),
      condition: !!data?.completeTime,
    },
  ];

  const renderTradeDetails = [
    {
      label: t('购买金额'),
      value: `${RenderUtil.FormatAmount(data?.amount)} ${
        ProjectSetting.APP_DEFAULT_CURRENCY
      }`,
    },
    {
      label: t('开仓价格'),
      value: `${RenderUtil.FormatAmount(data?.endPrice, priceAccuracy)} ${
        ProjectSetting.APP_DEFAULT_CURRENCY
      }`,
    },
    {
      label: t('结算价格'),
      value: `${RenderUtil.FormatAmount(data?.completePrice)} ${
        ProjectSetting.APP_DEFAULT_CURRENCY
      }`,
      condition: ishsy,
    },
    {
      label: t('当前价格'),
      value: `${RenderUtil.FormatAmount(
        socketData?.a || orderSocket?.currentPrice,
        priceAccuracy,
      )} ${ProjectSetting.APP_DEFAULT_CURRENCY}`,
      condition: !ishsy,
    },
    {
      label: ishsy ? t('已实现盈亏') : t('未实现盈亏'),
      value: `${RenderUtil.getDynamicPriceString(plAmount, 2)} ${
        ProjectSetting.APP_DEFAULT_CURRENCY
      }`,
      condition: true,
      className: plAmount > 0 ? currentUpClass : currentDownClass,
    },
    {
      label: t('盈亏比'),
      value: `${RenderUtil.getDynamicPriceString(plRatio, 2)} %`,
      condition: false,
      className: plRatio > 0 ? currentUpClass : currentDownClass,
    },
  ];

  return (
    <div className="m-2">
      <div className="flex flex-col justify-center items-center ">
        {+data?.status <= 0 ? (
          <div className="bg-success/20 text-success w-12 h-12 rounded-[100%] flex justify-center items-center">
            <FluentMdl2CompletedSolid width="2.5em" height="2.5em" />
          </div>
        ) : (
          <LineMdCoffeeLoop width="2.5em" height="2.5em" />
        )}
        <span
          className={`text-auxiliaryTextColor text-base mt-2 -[3px] font-bold ${
            data?.status <= 0 ? 'text-success' : 'text-error'
          }`}
        >
          {showTextMap[data?.status?.toString()]}
        </span>
      </div>

      <div className="mt-6">
        {renderDetailItems.map?.(
          (item, index) =>
            item.condition !== false && (
              <div key={index}>
                <div className="flex justify-between items-center mb-2 text-sm">
                  <span className="text-auxiliaryTextColor">{item.label}</span>
                  <div className="flex-1 flex justify-end items-center">
                    <span className="text-backContrastColor bg-[transparent] flex items-center">
                      {item.value}
                    </span>
                    {item.copyable && (
                      <PhCopyFill
                        className="cursor-pointer ml-2"
                        onClick={() => FormatUtils.copyText(data?.no)}
                      />
                    )}
                  </div>
                </div>
                {index === 1 && <Divider className="my-4 !bg-backgroundAuxiliaryColor" />}
              </div>
            ),
        )}

        <div className="mt-10">
          <div className="titleText text-lg mb-4">{t('成交明细')}</div>
          {renderTradeDetails.map?.(
            (item, index) =>
              item.condition !== false && (
                <div key={index} className="flex justify-between mb-2 text-sm">
                  <span className="text-auxiliaryTextColor">{item.label}</span>
                  <div
                    className={`text-backContrastColor ${item.className || ''}`}
                  >
                    {item.value}
                  </div>
                </div>
              ),
          )}
        </div>
      </div>
    </div>
  );
};
