export function areEqual(num1:number, num2:number, epsilon = Number.EPSILON) {
    return Math.abs(num1 - num2) < epsilon;
}
export function truncateNumber(
  num: number | any,
  decimalPlaces: number,
): string | undefined {
    if(num === undefined) return ;
    const strNum = num?.toString?.();
    const [integerPart, decimalPart] = strNum?.split?.('.') ?? [];

    if(decimalPlaces === 0)
        return  integerPart;

    if (!decimalPart || decimalPart.length <= decimalPlaces) {
        return strNum;
    }

    return `${integerPart}.${decimalPart.slice(0, decimalPlaces)}`;
}

export function ceilNumber(
  num: number | any,
  decimalPlaces: number,
): string | undefined {
  if (num === undefined) return undefined;

  const factor = Math.pow(10, decimalPlaces);
  const result = Math.ceil(num * factor) / factor;

  if (decimalPlaces === 0) {
    return result.toString();
  }

  const strResult = result.toString();
  const [integerPart, decimalPart] = strResult.split('.') ?? [];

  if (!decimalPart || decimalPart.length <= decimalPlaces) {
    return strResult;
  }

  return `${integerPart}.${decimalPart.slice(0, decimalPlaces)}`;
}
