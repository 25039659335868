import { LineMdCoffeeLoop } from '@/assets/icons/mine/LineMdCoffeeLoop';
import { FluentMdl2CompletedSolid } from '@/assets/icons/order/FluentMdl2CompletedSolid';
import { useTranslation } from 'react-i18next';

interface OrderCoinStatusIconProps {
  data?: any;
}

export function OrderFlashExchangeStatusIcon(props: OrderCoinStatusIconProps) {
  const { data } = props;
  const { t } = useTranslation();


  return (
    <div className="flex flex-col justify-center items-center mt-4 ">
      <div className="bg-success/20 text-success w-12 h-12 rounded-[100%] flex justify-center items-center">
        <FluentMdl2CompletedSolid width="2.5em" height="2.5em" />
      </div>
      {
        <span
          className={`text-success text-base mt-2 -[3px] font-bold `}
        >
          +{data?.amount}{' '+data?.exchangeCoin}
        </span>
      }
    </div>
  );
}
