import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';
import ProjectSetting from '@/setting/projectSetting';
import RenderUtil from '@/utils/RenderUtil';
import { FormatUtils } from '@/utils/format';
import { useModel } from '@@/exports';
import useUrlState from '@ahooksjs/use-url-state';
import { Divider } from '@nextui-org/react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useConstants from '../../../funds/orderHistory/index/useConstants';

enum TransactionTypeEnum {
  //限价
  LIMIT = 2,
  //市价
  MARKET = 1,
}

export default ({ data }: any) => {
  const { t } = useTranslation();
  const { getSocketRowByOrderId, getSocketRowByName } = useModel('socket');
  const socketData = getSocketRowByName(data?.symbol ?? data?.baseSymbol);
  const orderSocket = getSocketRowByOrderId(data?.id as any);



  const [urlState] = useUrlState<any>({});
  const { source } = urlState || {};
  const { sourceOptions } = useConstants();
  const { appInfo = {} } = useModel('appInfo');
  const { AUTH_LOGIN_CAPTCHA = true } = appInfo;
  const { joinSymbol } = useModel('exchange');
  const { sendMsg, isSub } = useModel('socket');

  useEffect(() => {
    sendMsg(
      `{"type":"SUB","data": ${JSON.stringify(
        data?.symbol ?? data?.baseSymbol,
      )}}`,
    );
  }, [data?.symbol, data?.baseSymbol, isSub]);
  const priceAccuracy = data?.priceAccuracy ?? orderSocket?.priceAccuracy ?? 8;
  console.log(data,"data");



  return (
    <div className="mt-4">
      <div>
        <div className='flex justify-between items-center mb-2 text-sm'>
        <span className="text-auxiliaryTextColor">{t('订单类型')}</span>
        <div className="flex justify-between text-sm">
            {/* <span className="text-auxiliaryTextColor">{t('保证金模式')}</span> */}
            {+data?.direction  === 1 && (
              <span className="text-backContrastColor">{t('开多')}</span>
            )}
            {+data?.direction  !== 1 && (
              <span className="text-backContrastColor">{t('开空')}</span>
            )}
          </div>
        </div>
        <div className="flex justify-between items-center mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('订单号')}</span>
          <div className="flex-1 flex justify-end items-center">
            <span className="text-backContrastColor bg-[transparent] flex items-center">
              {data?.subOrderNo}
            </span>
            <PhCopyFill
              className="cursor-pointer ml-2"
              onClick={() => FormatUtils.copyText(data?.subOrderNo)}
            />
          </div>
        </div>
        {/* <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('类型')}</span>
          <div>
            <span className="text-errorColor">
              {data?.type === TransactionTypeEnum.LIMIT
                ? `${t('限价')}`
                : `${t('市价')}`}
            </span>
            <span className="mx-1">/</span>
            {data?.direction === 1 && (
              <span className=" text-successColor flex-shrink-0">
                {t('买涨')}
              </span>
            )}
            {data?.direction === 2 && (
              <span className="text-errorColor flex-shrink-0">{t('买跌')}</span>
            )}
          </div>
        </div> */}
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">
            {t('金额')}
          </span>
          <span className="text-backContrastColor">
            {RenderUtil.FormatAmount(data?.inputPrice,priceAccuracy,true)}
            {/* {t("张")} */}
            {/* <span className="mx-1">/</span>
            {joinSymbol({
              price: RenderUtil.FormatAmount(data?.earnestMoney, priceAccuracy),
              currency: ProjectSetting.APP_DEFAULT_CURRENCY,
              isShowCoinName: true,
            })} */}
          </span>
        </div>
      </div>
      <Divider className="my-4 !bg-backgroundAuxiliaryColor" />
      <div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('创建时间')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.formatDate(data?.positionOpenTime,"YYYY-MM-DD HH:mm:ss")}
          </span>
        </div>
        { (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('平仓时间')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.formatDate(data?.positionCloseTime,"YYYY-MM-DD HH:mm:ss")}
            </span>
          </div>
        )}
      </div>
      <div className="mt-10">
        <div className="titleText text-lg mb-4">{t('成交明细')}</div>
        {/* {data?.completeTime && (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('日期')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.formatDate(data?.completeTime)}
            </span>
          </div>
        )} */}
        {/* <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('数量(张)')}</span>
          <span className="text-backContrastColor">
            {truncateNumber(data?.num,0)}
            {' '+t("张")}
          </span>
        </div> */}
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('开仓价格')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.FormatAmount(
              data?.startPrice,
              priceAccuracy,
              true,
            )}
          </span>
        </div>

        {(
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('平仓价格')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.FormatAmount(
                data?.endPrice,
                priceAccuracy,
                true,
              )}
            </span>
          </div>
        ) 
        // (
        //   <div className="flex justify-between mb-2 text-sm">
        //     <span className="text-auxiliaryTextColor">{t('强平价格')}</span>
        //     <span className="text-backContrastColor">
        //       {data?.forcedLiquidationPrice
        //         ? RenderUtil.FormatAmount(
        //             data?.forcedLiquidationPrice,
        //             priceAccuracy,
        //             true,
        //           )
        //         : '--'}
        //     </span>
        //   </div>
        // )
        }

        {/* {!ishsy && data?.status!==-1 &&(
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('当前价格')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.FormatAmount(
                socketData.a || orderSocket?.currentPrice,
                priceAccuracy,
                true,
              )}
            </span>
          </div>
        )} */}

        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('手续费')}</span>
          <span className="text-backContrastColor">
            {joinSymbol({
              price: RenderUtil.FormatAmount(data?.commission, 2, true),
              currency: ProjectSetting.APP_DEFAULT_CURRENCY,
              isShowCoinName: true,
            })}
          </span>
        </div>

        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">
            {t('收益')}
          </span>
          <div
            className={`text-backContrastColor ${
                data?.profitPrice > 0 ? ' text-successColor' : 'text-errorColor'
            }`}
          >
            {  data?.profitPrice > 0 ? '+' : ''}
            {joinSymbol({
              price: RenderUtil.FormatAmount(  data?.profitPrice, 2,true),
              currency: ProjectSetting.APP_DEFAULT_CURRENCY,
              isShowCoinName: true,
            })}
          </div>
        </div>

        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('收益率')}</span>
          <div
            className={`text-backContrastColor ${
                data?.profitRate > 0 ? ' text-successColor' : 'text-errorColor'
            }`}
          >
            {  data?.profitRate > 0 ? '+' : ''}
            {/* {RenderUtil.formatAndCeilToTwoDecimals(data?.profitRate*100)}  */}
            {RenderUtil.FormatAmount(data?.profitRate*100, 2, false)}
            %
          </div>
        </div>

        {/* <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">
            {t('止盈')}/{t('止损')}
          </span>
          <span className="text-backContrastColor">
            {data?.stopProfitPrice
              ? RenderUtil.FormatAmount(data?.stopProfitPrice, priceAccuracy)
              : '--'}
            <span className="mx-1">/</span>
            {data?.stopLossPrice
              ? RenderUtil.FormatAmount(data?.stopLossPrice, priceAccuracy)
              : '--'}
          </span>
        </div> */}

        {/* {AUTH_LOGIN_CAPTCHA && (
          <div className="flex justify-between mb-2 text-sm">
            {+data?.direction  === 1 && (
              <span className="text-backContrastColor">{t('开多')}</span>
            )}
            {+data?.direction  !== 1 && (
              <span className="text-backContrastColor">{t('开空')}</span>
            )}
          </div>
        )} */}
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('杠杆')}</span>
          <span className="text-backContrastColor">{data?.lever}x</span>
        </div>
      </div>
    </div>
  );
};
