import RenderUtil from '@/utils/RenderUtil';
import { Divider } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

interface OrderCoinDetailFormationProps {
  data?: any;
}

enum TransactionTypeEnum {
  //限价
  LIMIT = 2,
  //市价
  MARKET = 1,
}
// const showTextMap = {
//   '-2': t('已强平'),
//   '-1': t('已撤销'),
//   '0': t('全部成交') + '100%',
//   '1': t('委托中'),
//   '2': t('持仓中'),
// } as any;

export function OrderCoinDetailFormation(props: OrderCoinDetailFormationProps) {
  const { data } = props;

  const priceAccuracy = data?.priceAccuracy ?? 6;
  const transactionAccuracy = data?.transactionAccuracy ?? 6;

  const isTraded = [-2, 0].includes(data?.status);

  const { t } = useTranslation();
  return (
    <div className="mt-4">
      <div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('类型')}</span>
          <span className="text-errorColor">
            {data?.type === TransactionTypeEnum.LIMIT
              ? `${t('限价')}`
              : `${t('市价')}`}
          </span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('方向')}</span>
          {data?.direction === 1 && (
            <span className=" text-successColor flex-shrink-0">
              {t('买入')}
            </span>
          )}
          {data?.direction === 2 && (
            <span className="text-errorColor flex-shrink-0">{t('卖出')}</span>
          )}
        </div>

        {data?.type === TransactionTypeEnum.LIMIT && (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('委托价格')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.FormatAmount(data?.limitPrice, priceAccuracy)}
            </span>
          </div>
        )}
      </div>
      <Divider className="my-4 !bg-backgroundAuxiliaryColor" />
      <div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('交易时间')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.formatDate(data?.createTime,"YYYY-MM-DD HH:mm:ss")}
          </span>
        </div>
        {data?.type === TransactionTypeEnum.LIMIT && isTraded && (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('完成时间')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.formatDate(data?.finishTime,"YYYY-MM-DD HH:mm:ss")}
            </span>
          </div>
        )}
      </div>
      <div className="mt-10">
        <div className="titleText text-lg mb-4">{t('成交详情')}</div>
        {data?.completeTime && (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('成交日期')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.formatDate(data?.completeTime,"YYYY-MM-DD HH:mm:ss")}
            </span>
          </div>
        )}

        <div className="flex justify-between mb-2 text-sm">
          {/* <span className="text-auxiliaryTextColor">{t('交易金额')}</span> */}
          <span className="text-auxiliaryTextColor">{t('数量')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.FormatAmount(data?.baseAmount, transactionAccuracy)}
            {` ${data?.baseSymbolName}`}
          </span>
        </div>
        {isTraded && (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('成交金额')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.FormatAmount(data?.exchangeAmount, priceAccuracy) ??
                '--'}
              {` ${data?.exchangeSymbolName}`}
            </span>
          </div>
        )}

        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('价格')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.FormatAmount(data?.startPrice, priceAccuracy) ?? '--'}
            {` ${data?.exchangeSymbolName}`}
          </span>
        </div>

        {data?.type === TransactionTypeEnum.LIMIT && isTraded && (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('完成金额')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.FormatAmount(data?.endPrice, priceAccuracy) ?? '--'}
              {` ${data?.exchangeSymbolName}`}
            </span>
          </div>
        )}

        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('手续费')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.FormatAmount(data?.fee, 8)}
            {data?.direction === 1
              ? ` ${data?.baseSymbolName}`
              : ` ${data?.exchangeSymbolName}`}
          </span>
        </div>
      </div>
    </div>
  );
}
