import { useCountDown } from 'ahooks';
import { HTMLAttributes } from 'react';

/**
 * 倒计时组件
 * @param {number} time 传入秒数量
 * @param {string} [suffix=''] 后缀字符串
 * @param {string} [className=''] CSS类名
 * @param {Function} [onEnd] 结束时调用的函数
 * @param {number} [delay=0] 延迟调用onEnd方法的秒数
 */
const BaseCountDown = ({
  time = 0,
  suffix = '',
  className = '',
  onEnd,
  delay = 0,
}: iprops) => {
  const [countdown] = useCountDown({
    leftTime: time * 1000,
    onEnd: () => {
      setTimeout(() => {
        onEnd?.();
      }, delay * 1000);
    },
  });

  return (
    <span className={className}>
      {Math.round(countdown / 1000)}
      <span className="ml-1">{suffix}</span>
    </span>
  );
};

export default BaseCountDown;

type iprops = {
  time: number;
  suffix?: string;
  className?: HTMLAttributes<HTMLSpanElement>['className'];
  onEnd?: () => void; // 修改 onEnd 回调函数类型
  delay?: number; // 增加 delay 属性类型
};
